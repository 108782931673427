<template>
    <v-dialog v-model="deleteDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!isIcon"  v-on="on" v-bind="attrs" color="red" depressed class="white--text" >{{
                            $t(`${name}`) }}</v-btn>
        <v-icon v-if="isIcon" v-on="on" v-bind="attrs" color="red">mdi-delete</v-icon>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center card-title__heading">
          <h2> {{ $t('are you sure you want delete') }} </h2>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            color="green"
            class="white--text"
            depressed
            @click="deleteRecord"
            :loading="deleteBtnLoading"
            > {{ $t('delete') }} </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import Notifications from "../../../../helpers/toast-notifications";
  
  export default {
    data() {
      return {
        deleteBtnLoading: false,
        deleteDialog: false,
      };
    },
    props: {
      id: [String, Number],
      path: String,
      name: String,
      isIcon: {
        type: Boolean,
        default: true,
      },
      params: {
        type: Object,
      },
    },
    methods: {
      async deleteRecord() {
        try {
          this.deleteBtnLoading = true;
          // const filterTemp = {};
          if(this.path=='final-grade-student'){
            if(this.isIcon) {
              this.params = {id: this.id};
            }
            await axios.post(`/${this.path}/delete`, this.params);
          } 
          // else {
          //   Object.keys(this.params).forEach((key) => {
          //       if (this.params[key]) filterTemp[key] = this.params[key];
          //   });
          // }
          else {
            await axios.delete(`/${this.path}/${this.id}`);
          }

          this.$emit("record-deleted");
          this.deleteDialog = false;
          Notifications(
            this.$t('delete success'),
            { timeout: 2000, rtl: true },
            "success"
          );
        } catch (err) {
        } finally {
          this.deleteBtnLoading = false;
        }
      },
    },
  };
  </script>
  
  <style></style>
  