<template>
  
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew"
          rounded
          v-on="on"
          v-bind="attrs"
          color="green"
          depressed
          class="white--text"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add Results Structure") }}
        </v-btn>
        <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
       
       
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("add structure") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                :label="$t('Label')"
                v-model="structure.label"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-select
                v-model="structure.color"  
                color="#757575" 
                :label="$t('Color')"
                :items="colors"
                outlined
                dense
            ></v-select>
            </v-col>
            <v-col md="6">
              <v-text-field
              type="number"
                outlined
                dense
                :label="$t('Min')"
                v-model="structure.min"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
              type="number"
                outlined
                dense
                :label="$t('Max')"
                v-model="structure.max"
              ></v-text-field>
            </v-col>
           
           
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            depressed
            class="white--text"
            @click="submit"
            :loading="btnLoading"
            >{{ $t("storage") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      dialog: false,
    
      colors:['BLACK','RED','ORANGE','LIGHTBLUE','BLUE','LIGHTGREEN','GREEN'],
      
      structure: {
        min: null,
        max: null,
        label: null,
        color: "",
      
      },
    };
  },
  props: {
    
    isNew: {
            type: Boolean,
            default: true,
        },
      item: {
        type: Object,
        default: () => {
                return {};
            },
      }  
  },
  computed: {
 
  },
  methods: {
    
 

    async submit() {
      try {
       
        this.btnLoading = true;
        if(this.isNew) {
          await axios.post("/grade-structure", this.structure);
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        } else{
          await axios.put(`/grade-structure/${this.item.id}`, this.structure);
          this.$Notifications(
            this.$t('edit success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        }
        
        this.$emit("Submitted");
       this.clearData();
      } catch (err) {
      } finally {
        this.btnLoading = false;
      
      }
    },
    clearData(){
      this.structure.color = null;
      this.structure.label = null;
      this.structure.max = null;
      this.structure.min = "";
    
     
        this.dialog = false;
    },
  },
  created() {
 
    if(!this.isNew){
 
      
      this.structure.color = this.item.color;
      this.structure.label = this.item.label;
      this.structure.max = this.item.max;
      this.structure.min = this.item.min;
    
  
    } 

  },
};
</script>

<style>
</style>